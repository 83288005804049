.sf__results {
    padding: 0 30px;
    width: 75%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;

    @media screen and (max-width: $tablet-breakpoint) {
        width: 100%;
        padding: 0 20px; 
    }
}

.sf__results__list {
    display: flex;
    flex-wrap: wrap;  
}

.sf__results__result {
    width: calc(25% - 30px); 
    margin: 15px;
    text-align: center;
    cursor: pointer; 
    display: flex;
    flex-direction: column;
    position: relative; 

    @media screen and (max-width: 1024px) {
        width: calc(33.33333% - 30px);
    }

    @media screen and (max-width: $tablet-breakpoint) {
        width: calc(50% - 30px);
    }

    img {
        width: 100%; 
    }
}

.sf__results__result__image {
    position: relative;
    width: 100%; 
    border: 1px solid $seatshop-light-grey;

    &:after {
        content: '';
        display: block;
        padding-top: 100%; 
    }

    img {
        position: absolute;
        top: 0; 
        left: 0; 
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: opacity .3s ease;

        &.loaded {
            opacity: 1;
        }
    }

    p {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-weight: bold;
        text-transform: uppercase;
        padding: 20px;
        box-sizing: border-box;
        opacity: .3;
    }
}

.sf__results__result__position {
    color: $seatshop-primary; 
    font-weight: bold;
    text-transform: uppercase;
    margin: 20px 0 0;
    font-size: .75em;
}

.sf__results__result__title {
    margin-top: 10px; 
}

.sf__results__result__subtitle {
    margin: -10px 0 5px;
    font-size: 15px;
    color: $seatshop-primary; 

    span {
        color: $seatshop-black; 
        display: block; 
        font-size: .8em;
    }
}

.sf__results__result__sku {
    font-size: .8em;
    opacity: .7;
}

.sf__results__result__price {
    font-weight: bold;
    font-size: 1.1em;
    margin-top: auto;

    span.sale {
        display: block;
        color: $seatshop-blue;

        & + span.regular {
            display: block; 
            text-decoration: line-through;
            opacity: .6; 
        }
    }
}

.sf__results__result__add-to-cart {
    border: none;
    background: $seatshop-primary;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .3px;
    color: white;
    position: relative;

    &.hidden {
        visibility: hidden;
    }

    &[disabled] {
        opacity: .5;
        cursor: not-allowed; 
    }

    @media screen and (max-width: $mobile-breakpoint) {
        font-size: .8em;
    }
}

.sf__results__result__add-to-cart__loader {
    display: inline-block !important;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.sf__results__result__add-to-cart__loader:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 0px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: sf__results__result__add-to-cart__loader 1.2s linear infinite;
}
@keyframes sf__results__result__add-to-cart__loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.sf__results__tabs {
}

.sf__result__tabs__list {
    min-height: 30px;
    display: flex;
    border-bottom: 1px solid $seatshop-primary;
    padding: 0 5px;
    margin-bottom: 30px; 
    box-sizing: border-box;
    margin-top: 70px;

    @media screen and (max-width: $tablet-breakpoint) {
        padding: 0; 
        flex-wrap: wrap;
        width: 100%; 
    }

    div {
        border: 1px solid $seatshop-primary;
        padding: 5px 30px;
        display: inline-flex;
        align-items: center;
        border-bottom: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
        position: relative; 

        @media screen and (max-width: $tablet-breakpoint) {
            border: none; 
            width: 100%; 
            padding: 10px;
        }

        @media screen and (max-width: $mobile-breakpoint) {
            font-size: 14px; 
        }

        &.active {
            &:after {
                content: ''; 
                display: block; 
                position: absolute; 
                background: white;
                left: 0;
                bottom: -1px;
                width: 100%;
                height: 1px;
            }
        }

        &:not(.active) {
            cursor: pointer;
            background: #f2f2f2;
        }

        &:not(:first-child) {
            border-left: none; 
        }
    }
}


.sf__result__shortcuts {
    display: flex; 
    justify-content: center;
    flex-wrap: wrap;
    margin: 15px 0; 

    a {
        display: inline-block;
        margin: 5px;
        background: $seatshop-primary;
        color: white;
        padding: 9px 20px;
        border-radius: 9999px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
        cursor: pointer; 
        text-decoration: none;
    }
}

.sf__result__section__title {
    font-size: 20px;
    text-transform: uppercase;
    border-top: 1px solid $seatshop-primary;
    padding-top: 30px;
}

.sf__parts__modal {
    position: fixed; 
    background: rgba(0,0,0,0.6); 
    top: 0; 
    bottom: 0; 
    left: 0;
    right: 0; 
    z-index: 1000;
}

.sf__parts__modal__inner {
    width: calc(100% - 60px); 
    max-width: 800px; 
    max-height: 90vh;
    background: #fff; 
    position: absolute;
    left: 50%; 
    top: 50%; 
    transform: translate(-50%, -50%);
    overflow-y: auto;
    padding: 20px 30px; 
}

.sf__parts__modal__list {
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
}


.sf__results__result__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(255,255,255,0.5);
}


.sf__results__alerts {

}

.sf__result__alert {
    padding: 15px;
    border-radius: 5px;
    background: $seatshop-primary;
    color: white;
    font-size: .9em;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 18px;

    &:last-child {
        margin-bottom: 0;
    }
}

.sf__result__alert__image {
    max-width: 100px;
    width: 30%;
    flex-shrink: 0; 
}

.sf__result__alert__content {
    padding: 0 10px;
    flex-grow: 1;
    padding-left: 30px;

    p:first-child {
        text-transform: uppercase;
        font-weight: bold; 
        margin-top: 0; 
    }

    p {
        margin-top:  5px; 
        margin-bottom: 0;
    }
}

.sf__results__alerts + .sf__results__tabs {
    .sf__result__tabs__list {
        margin-top: 20px; 
    }
}