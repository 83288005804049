.sf__part-exceptions {
    box-sizing: border-box;
    border: 1px solid $seatshop-primary; 
    padding: 20px; 

    &:after {
        content: ''; 
        display: block; 
        width: 100%; 
        clear: both; 
    }

    h3 {
        margin: 0 0 15px; 
    }

    img {
        max-width: 300px; 
        width: 40%; 
        float: right; 
        margin: 0 0 10px 20px; 
        box-shadow: 0px 0px 1px #f2f2f2;
    }
}