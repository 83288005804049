$seatshop-primary: #db4426; 
$seatshop-light-grey: #f2f2f2;
$seatshop-black: #000; 
$seatshop-blue: #0104b3; 

$tablet-breakpoint: 767px; 
$mobile-breakpoint: 479px; 

.sf__container {
    min-height: 100vh; 
}

@import 'transitions'; 
@import 'loader'; 
@import 'vehicles'; 
@import 'filters'; 
@import 'results'; 
@import 'install-videos'; 
@import 'exceptions'; 