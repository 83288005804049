.sf__install-videos {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    padding: 60px 15px;

    @media screen and (max-width: $tablet-breakpoint) {
        padding: 30px 15px; 
    }
}

.sf__install-video {
    width: calc(25% - 20px); 
    box-sizing: border-box; 
    margin: 0 10px 20px;
    cursor: pointer; 

    @media screen and (max-width: $tablet-breakpoint) {
        width: calc(50% - 20px);
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: calc(100% - 20px);
    }
}

.sf__install-video__image {
    width: 100%; 
    position: relative; 

    &:after {
        content: '';
        display: block;
        padding-top: 56.25%; 
    }

    img {
        position: absolute;
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%;
        object-fit: cover;
    }
}

.sf__install-video__modal {
    background: rgba(0,0,0,0.6);
    position: fixed; 
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0; 
    z-index: 10000;
}

.sf__install-video__modal__inner {
    background: #000;
    position: absolute; 
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: calc(100% - 60px); 
    max-width: 1024px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    &:after {
        content: '';
        display: block; 
        padding-top: 56.25%;
    }

    iframe {
        position: absolute;
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%;
    }
}

// .sf__install-videos {
//     position: fixed; 
//     top: 0; 
//     left: 0; 
//     right: 0; 
//     bottom: 0; 
//     background: rgba(0,0,0,0.8); 
//     z-index: 1000;
// }

// .sf__install-videos__inner {
//     width: calc(100% - 60px);
//     max-width: 1024px;
//     position: absolute;
//     background: black;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     padding: 30px; 
// }

// .sf__install-videos__list {
//     display: flex;
//     flex-wrap: wrap;
//     width: 100%; 
// }

// .sf__install-videos__item {
//     width: calc(33.33333% - 20px);
//     position: relative;
//     cursor: pointer; 
//     margin: 10px; 

//     &:after {
//         content: ''; 
//         display: block; 
//         padding-top: 56.25%;
//     }

//     img {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         display: block; 
//         object-fit: cover;
//         opacity: .8;
//     }

//     svg {
//         color: white;
//         opacity: .6;
//         max-width: 20%;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//     }
// }


// .sf__install-video {
//     position: fixed; 
//     top: 0; 
//     left: 0; 
//     right: 0; 
//     bottom: 0; 
//     background: rgba(0,0,0,0.8); 
//     z-index: 1000;
// }

// .sf__install-video__inner {
//     width: calc(100% - 60px);
//     max-width: 1024px;
//     position: absolute;
//     background: black;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);

//     &:after {
//         content: ''; 
//         display: block; 
//         padding-top: 56.25%;
//     }

//     iframe {
//         position: absolute; 
//         top: 0; 
//         left: 0; 
//         width: 100%;
//         height: 100%;
//     }
// }

