.sf__filters {
    width: 33.3333%;
    max-width: 400px;
    flex-shrink: 0;

    @media screen and (max-width: $tablet-breakpoint) {
        &:not(.sf__filters--popup) {
            position: fixed;
            top: 0; 
            left: 0;  
            width: calc(100% - 30px); 
            z-index: 100000;
            height: 100vh;
            transform: translateX(-101%);
            // transition: transform .15s ease;

            &.sf__filters--show {
                transform: translateX(0);
            }

            .sf__filters__inner {
                height: 100vh;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
            }

            .sf__filters__close {
                display: flex; 
            }
        }
    }

    h1 {
        text-transform: uppercase;
        font-weight: 900;
        margin-top: 0; 
        font-size: 1.8em; 

        @media screen and (max-width: $mobile-breakpoint) {
            font-size: 1.5em;
        } 
    }

    .sf__filters__close {
        color: gray;
        text-decoration: none;
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        display: none;
    }

    .sf__filters__inner {
        background: #fafafa; 
        padding: 20px; 
        height: 100%;
        overflow: hidden;
        border-right: 1px solid $seatshop-light-grey;
    }
}

.sf__filter {
    h3 {
        text-transform: uppercase;
        margin: 10px 0; 

        span {
            display: inline-block; 
        }
    }
}

// .sf__filter--active {
//     .sf__filter__values {
//         display: flex; 
//     }
// }

.sf__filter__values {
    // display: none; 
    display: flex; 
    justify-content: center; 
    flex-wrap: wrap; 
}

.sf__filter__value {
    flex-shrink: 0;
    margin: 10px; 
    position: relative; 
    cursor: pointer; 

    p {
        text-align: center;
    }
}

.sf__filter__value--with-image {
    width: calc(33.333333% - 20px);  
}

.sf__filter__value--unavailable {
    opacity: .5; 
    cursor: not-allowed !important;
}

.sf__filter__value--hidden {
    display: none;
}

.sf__filter__value:not(.sf__filter__value--with-image) {
    display: inline-flex;
    align-items: center;
    justify-content: center; 
    box-sizing: border-box;
    padding: 5px 15px; 
    background: $seatshop-light-grey; 
    color: #000;
    border: 3px solid transparent; 
    cursor: pointer; 
    width: calc(100% - 20px);
    min-height: 40px;
    border: 3px solid darken($seatshop-light-grey, 1%); 

    p {
        margin: 0;
        text-transform: uppercase;
        // opacity: .5;
        font-size: 13px; 
        font-weight: bold;
    }

    &.sf__filter__value--active {
        border: 3px solid $seatshop-primary; 
        p { opacity: 0.9 }
    }
}

.sf__filter__value--active {
    .sf__filter__value__image:before {
        content : ''; 
        display: block; 
        width: 100%; 
        height: 100%;
        border: 5px solid $seatshop-primary; 
        box-sizing: border-box;
        position: absolute;
    }
}

.sf__filter__value__image {
    width: 100%; 
    position: relative; 
    background-size: cover; 
    background-color: rgba(200,200,200,0.2); 
    border: 1px solid $seatshop-light-grey;

    &:after {
        content: '';
        display: block; 
        padding-top: 100%;
    }

    span {
        position: absolute;
        width: 100%; 
        visibility: hidden;
    }
}

.sf__filter__value__label--oem {

    &:before {
        content: 'OEM'; 
        display: block;
        position: absolute;
        top: 0; 
        left: 0; 
        transform: translate(-50%, -50%);
        background: #a9a9a9;
        // background: $seatshop-primary;
        color: white;
        padding: 3px 5px;
        border-radius: 4px;
        font-size: .8em;
        font-weight: bold;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }

    .sf__filter__value--active &:before {
        background: $seatshop-primary;
    }
}

.sf__filters__apply {
    display: none; 
    text-transform: uppercase;
    border-radius: 9999px; 
    background: $seatshop-primary;
    color: white;
    border: none;
    padding: 10px 30px;
    font-size: 20px;
    font-weight: bold;
    transition: background .15s ease;
    cursor: pointer;

    &:hover {
        background: lighten($seatshop-primary, 5%);
    }

    &[disabled] {
        opacity: .5;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        font-size: 15px;
        padding: 10px 15px; 
        line-height: 1;
    }
}


.sf__filters--popup {
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    background: rgba(0,0,0,0.4);
    width: auto;
    max-width: none;
    z-index: 10000;

    .sf__filters__inner {
        width: calc(100% - 30px); 
        box-sizing: border-box;
        max-width: 700px; 
        max-height: calc(100vh - 60px);
        box-shadow: 0 2px 8px rgba(0,0,0,0.4);
        height: auto;
        position: absolute; 
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);
        border-radius: 5px; 
        overflow-y: auto;
        background: white; 
        padding: 40px; 
        border-right: none;

        @media screen and (max-width: $mobile-breakpoint) {
            padding: 20px; 
        }
    }
    
    h1 {
        text-align: center;
    }

    .sf__filter h3 {
        font-size: 1.4em; 
        margin: 20px 0;
        text-align: center;

        @media screen and (max-width: $mobile-breakpoint) {
            font-size: 1.2em;
        }

        span {
            padding: 0 20px; 
        }
    }

    .sf__filter__value--with-image {
        width: calc(25% - 20px);

        @media screen and (max-width: $tablet-breakpoint) {
            width: calc(33.33333% - 20px);             
        }
    }

    .sf__filter__value:not(.sf__filter__value--with-image) {
        padding: 5px 30px;
        width: calc(33.33333% - 20px); 

        @media screen and (max-width: $mobile-breakpoint) {
            width: calc(100% - 20px); 
        }
    }
    
    .sf__filters__apply {
        display: block;
    }

    .sf__filters__tooltip__trigger {
        text-align: center;
    }

}


.sf__filters__tooltip__trigger {
    font-size: .9em;
    display: block;
    color: $seatshop-primary;
    margin-top: 0; 
    font-weight: bold;

    a {
        cursor: pointer; 
    }
}

.sf__filters__tooltip {
    position: fixed;
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    background: rgba(0,0,0,0.7); 
    z-index: 10000;
}

.sf__filters__tooltip__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    background: white;
    transform: translate(-50%, -50%);
    width: calc(100% - 60px);
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.4);
    padding: 20px;
    box-sizing: border-box;
    max-width: 400px;

    h5 {
        margin: 0 0 20px; 
        font-size: 1.2em; 
    }
}

.sf__alert {
    margin: 20px 0; 
    padding: 5px 10px; 
    border: 1px solid $seatshop-primary; 

    p {
        margin: 0; 
        line-height: 1.3em;
        font-size: 14px;
        color: $seatshop-primary;
    }
}

.sf__filters__update-filters-button {
    text-transform: uppercase;
    border: 1px solid $seatshop-primary;
    padding: 10px;
    background: transparent;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;

    @media screen and (min-width: $tablet-breakpoint + 1px) {
        display: none;         
    }
}

.sf__filters__section__actions {
    display: flex; 
    margin: 30px auto 0;  
    justify-content: space-around;
    align-items: center;
}

// These are for when we want to hide the unavailable options and
// show everything in a sequential way. 
.sf__filters__section--hide-unavailable {

    .sf__filter + .sf__filter {
        display: none;
    }

    .sf__filter.sf__filter--selected + .sf__filter {
        display: block; 
    }

    .sf__filter__value--unavailable {
        display: none !important; 
    }
}

.sf__filters__summary {
    margin: 0 0 30px; 
    ul {
        list-style: none;
        padding: 0; 
        li {
            margin: 10px 0; 
            cursor: not-allowed;
            opacity: .75;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            span {
                flex: 1; 
                font-size: .9em;

                strong {
                    display: block; 
                    text-transform: uppercase;
                    font-size: .9em;
                    margin-bottom: 3px; 
                }
            }

            .sf__alert {
                width: 100%; 
            }
        }
    }

    img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        display: inline-block;
        vertical-align: middle;
        border: 1px solid #dedede;
        margin-right: 10px;
    }
}

.sf__filter__restricted {
    display: flex; 
    flex-wrap: wrap;
    align-items: center;
    max-width: 400px;
    margin: 0 auto; 
    align-items: center;
}

.sf__filter__restricted__content {
    flex: 1; 

    .sf__alert {
        margin-bottom: 0; 
    }
}

.sf__filter__restricted__image {
    width: 100px;
    height: 100px;
    background-size: cover;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #dedede;
    margin-right: 15px;
    flex-shrink: 0;
}

.sf__filter__restricted__label {
    margin: 0; 
}

.sf__filters__edit {
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: .8em;
    color: $seatshop-primary;

    &:hover {
        color: lighten($seatshop-primary, 10%);
    }
}

.sf__filters__close__popup {
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    position: absolute;
    right: 0px;
    top: 6px;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer; 
}

.sf__filters:not(.sf__filters--popup) .sf__filters__close__popup {
    display: none;
}