.sf__vehicles__options {
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: $tablet-breakpoint) {
        flex-direction: column;
        max-width: 480px;
        margin: 0 auto; 
    }

    button {
        padding: 0 30px;
        border: none;
        font-size: 16px;
        font-weight: bold;
        margin: 0 20px;
        background: $seatshop-primary; 
        color: white; 
        text-transform: uppercase;
        transition: background .15s ease;
        cursor: pointer; 
        white-space: nowrap;

        @media screen and (max-width: $tablet-breakpoint) {
            height: 40px;
            line-height: 40px;
        }

        &:hover {
            background: lighten($seatshop-primary, 5%);
        }
    }
}

.sf__vehicles__option {
    padding: 0 20px; 
    flex: 1; 

    @media screen and (max-width: $tablet-breakpoint) {
        margin-bottom: 30px;
    }

    select {
        width: 100%;
        appearance: none;
        border: none; 
        padding: 10px 15px; 
        color: $seatshop-primary; 
        text-transform: uppercase;
        font-weight: bold;
        border: 1px solid #dedede;
        text-align: center;
        cursor: pointer;

        &[disabled] {
            opacity: .5; 
            background: $seatshop-light-grey;
        }

        option span {
            display: block; 
        }

        &.errored {
            border: 1px solid $seatshop-primary;
        }
    }
}

.sf__vehicles__error {
    padding: 0px 20px; 
    text-align: left;
    color: $seatshop-primary;
    font-size: .9em;
    font-weight: 600;
}